import "./SuspenseLoading.scss";

export default function SuspenseLoading() {
  return (
    <div className="container flex h-screen w-screen flex-col items-center justify-center py-6 text-xl text-neutral-600 md:py-8">
      <div className="flex items-center justify-center">
        <img
          src="/logo.png"
          alt=""
          loading="lazy"
          height={100}
          className="w-auto max-w-full"
        />
      </div>
      <div className="flex items-center justify-center space-x-2">
        <span className="suspense_loading" />
      </div>
    </div>
  );
}
